var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e(), _c('div', {
          staticClass: "cyui-driections-navbar"
        }, _vm._l(_vm.dataList, function (item) {
          return _c('div', {
            staticClass: "drictions-list",
            on: {
              "click": function ($event) {
                return _vm.goDetail(item);
              }
            }
          }, [_c('div', {
            staticClass: "drictions-list-row"
          }, [_c('span', [_vm._v(_vm._s(item.name))]), _c('van-icon', {
            attrs: {
              "name": "arrow"
            }
          })], 1), _c('div', {
            staticClass: "van-hairline--bottom"
          })]);
        }), 0)];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };