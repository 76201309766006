import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
export default {
  name: 'DirectionsList',
  mixins: [common],
  data() {
    return {
      headerTitle: '使用说明',
      dataList: [{
        name: '系统操作手册',
        id: 1
      }, {
        name: '相关业务使用手册',
        id: 2
      }, {
        name: 'Q&A手册',
        id: 3
      }]
    };
  },
  props: {},
  methods: {
    // 进入详情
    goDetail(data) {
      let {
        id
      } = data;
      if (id == 1 || id == 2) {
        this.$router.push({
          name: 'DirectionsSystem',
          query: {
            id
          }
        });
      } else {
        this.$router.push({
          name: 'DirectionsQA',
          query: {
            id
          }
        });
      }
    }
    //
    //
  },

  filters: {},
  //
  created() {
    const {
      token,
      projectId,
      userId,
      userName
    } = this.$route.query;
    if (token && projectId) {
      this.$store.commit('user/SET_TOKEN', token);
      this.$store.commit('user/SET_USER_INFO', {
        dcProjectId: projectId,
        userId,
        userName
      });
      this.$store.commit('user/SET_PROJECT_INFO', {
        dcProjectId: projectId
      });
    }
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  },
  beforeRouteUpdate(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  }
};